import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { AuthService } from '../../service/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirestoreService } from '../../service/firestore/firestore.service';
import { EventsService } from '../../service/events/events.service';
import { FirestoreservicesService } from '../../service/firestoreservices/firestoreservices.service';
import { Router } from '@angular/router';
import { User } from '../../models';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../service/notification/notification.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;
  user = new User();
  displayName: string = '';
  photoURL: string = '';
  sub1: Subscription;
  countAlert = 0;
  order: any[] = [];
  releasedOrders: any[] = [];
  alertColor = "warning";

  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  cashWithdrawalWallet: any[] = [];
  isYellowGoAffiliate: boolean = false;
  @ViewChild('lgModal') lgModal: any;
  sub2: Subscription;
  sub3: Subscription;
  sufix_yet: string = "_local_yet";
  sufix_client: string = "_local_client";
  view: string;
  orders: any[] = [];
  chat = [];
  message = '';
  sufix = '';
  tableDataCategories = [];
  tableDataProducts = [];
  section_purchase_request: boolean = false;
  cash_flow_statement: boolean = false;

  constructor(
    public navServices: NavService,
    private router: Router,
    public auth: AuthService,
    private afAuth: AngularFireAuth,
    private firestore: FirestoreService,
    public events: EventsService,
    public ordersDB: FirestoreservicesService,
    private categoriesDB: FirestoreservicesService,
    private notificacionSound: NotificationService,
    private modalService: NgbModal,
    private notification: NotificationService,
  ) { }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }


  ngOnInit(): void {
    this.loadAlerts();
    let this_ = this;
    this.events.subscribe('alert:changeColor', (data) => {
      if (data['change']) {
        this.alertColor = this_.alertColor == "warning" ? "danger" : "warning";
        //console.log(this.alertColor);
        //this.changeColor();
      } else {
        //clearInterval(this.intervalSound); 
        this.alertColor = "warning";
      }
    });
  }

  loadAlerts() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    this.afAuth.authState.subscribe((userFirebase: any) => {
      if (userFirebase) {
        // console.log('auth getUserFirebase() : ', userFirebase.uid);
        this.firestore.get('users/' + userFirebase.uid).then(ref => {
          // console.log('auth get() : ', ref);
          ref.valueChanges().subscribe((userLocal: any) => {
            // console.log('auth getUserFirestore() : ', userLocal);
            this.user = userLocal;
            this.loadInProcessAndDelivered(userLocal);
            this.firestore.getOneWatcher('settings', 'partner').subscribe(partner => {
              if (partner.section_purchase_request) {
                this.section_purchase_request = partner.section_purchase_request;
              }
              // this.section_purchase_request = true;
            });
            this.loadCommerce(this.user.uid);
            this.loadReleasedOrders();
            this.displayName = userLocal.who_receives != undefined && userLocal.who_receives != null && userLocal.who_receives != '' ? userLocal.who_receives : '';
            this.photoURL = userLocal.photoURL != undefined && userLocal.photoURL != null && userLocal.photoURL != '' ? userLocal.photoURL : '../assets/img/profile.png';
            this.ordersDB.getAlertsAsync(userLocal.uid, 'orders').subscribe(res2 => {
              if (res2.length > 0) {
                let news = res2.filter(item => item['orders_status_uid'] == 'Creada');
                if (news.length > 0) {
                  this.order = news;
                  // console.log("order: ", this.order);
                  this.setCountAlert(news.length);
                  this.notificacionSound.requestPermission().subscribe(permission => {
                    this.events.publish('main:alert', { from: 'header', loadAlert: true, alerts: news });
                    this.events.publish('alert:sound', { from: 'header', sound: true });
                  });
                } else {
                  this.countAlert = 0;
                  this.events.publish('alert:sound', { from: 'header', sound: false });
                }
              }
            }, (e) => { console.log(e) });
            this.firestore.getWhere2_1('cash_withdrawal_wallet', 'manager_user_uid', this.user.uid, 'status_uid', ['Creada', 'Aceptada']).subscribe(async res => {
              // console.log("cash_withdrawal_wallet: ", res);
              this.cashWithdrawalWallet.length = 0;
              if (res.length !== 0) {
                res.sort(function (a, b) {
                  var keyA = a.create_date.seconds, keyB = b.create_date.seconds;
                  if (keyA < keyB) return 1;
                  if (keyA > keyB) return -1;
                  // a must be equal to b
                  return 0;
                });
                res.forEach(element => {
                  this.cashWithdrawalWallet.push(element);
                });
                this.notificacionSound.requestPermission().subscribe(permission => {
                  this.events.publish('alert:sound_2', { from: 'header', sound: true });
                });
              } else {
                this.events.publish('alert:sound_2', { from: 'header', sound: false });
              }
            })
          });
        }).catch((e) => {
        });
      }
    });
  }

  async loadInProcessAndDelivered(user) {
    this.firestore.getWhere6('orders', 'manager_uid', '==', user.uid, 'orders_status_uid', '!=', 'Entregada').subscribe((res0) => {
      // console.log("Appcomponent loadInProcessAndDelivered: ", res0)
      if (res0.length > 0) {
        this.orders = res0;

        if (this.sub2) { this.sub2.unsubscribe(); }
        this.sub2 = this.firestore.getOneWatcher('orders_chat', `${res0[0].uid}${this.sufix_client}`).subscribe(chat0 => {
          // console.log("orders_chat: ", chat0);
          if (chat0 != undefined && chat0.hasOwnProperty('messages') && chat0.messages.length > 0) {
            if (chat0.active_notification !== undefined && chat0.active_notification !== false && chat0.messages.slice(-1)[0].rol !== 'local') {
              this.view = 'CHAT CLIENTE';
              chat0.active_notification = false;
              this.presentToastWithOptions("Tienes un nuevo mensaje en el chat del cliente", "", "top-end", "#E0B006");
              this.firestore.update('orders_chat', `${res0[0].uid}${this.sufix_client}`, chat0).then((res2) => {
                let audio = new Audio();
                audio.src = "./../../assets/audio/notificacion_iphone.mp3";
                audio.load();
                audio.play();
                setTimeout(() => {
                  audio.load();
                  audio.play();
                }, 1000)
              });
            }
          }
        });

        if (this.sub3) { this.sub3.unsubscribe(); }
        this.sub3 = this.firestore.getOneWatcher('orders_chat', `${res0[0].uid}${this.sufix_yet}`).subscribe(chat1 => {
          // console.log("orders_chat: ", chat1);
          if (chat1 != undefined && chat1.hasOwnProperty('messages') && chat1.messages.length > 0) {
            if (chat1.active_notification !== undefined && chat1.active_notification !== false && chat1.messages.slice(-1)[0].rol !== 'local') {
              this.view = 'CHAT YET';
              chat1.active_notification = false;
              this.presentToastWithOptions("Tienes un nuevo mensaje en el chat del yet", "", "top-end", "#2a3142");
              this.firestore.update('orders_chat', `${res0[0].uid}${this.sufix_yet}`, chat1).then((res2) => {
                let audio = new Audio();
                audio.src = "./../../assets/audio/notificacion_iphone.mp3";
                audio.load();
                audio.play();
                setTimeout(() => {
                  audio.load();
                  audio.play();
                }, 1000)
              });
            }
          }
        });
      }
    });
  }

  async presentToastWithOptions(Tittle, message, position, color) {
    const Toast = Swal.mixin({
      toast: true,
      position: position,
    });

    Toast.fire({
      title: Tittle,
      background: color,
      showCancelButton: true,
      color: "#ffffff",
      cancelButtonColor: "#777777",
    }).then((result) => {
      /* Read more about isConfirmed */
      if (result.isConfirmed) {
        this.openXlModal();
        this.router.navigate(['order-show/' + this.orders[0].uid]);
      }
    });
  }

  loadReleasedOrders() {
    this.firestore.getWhere('orders', 'orders_status_uid', 'Liberada').subscribe(res => {
      if (res && res.length > 0) {
        this.releasedOrders = res;

        // this.notificacionSound.requestPermission().subscribe(permission => {
        //   this.events.publish('main:alert', { from: 'header', loadAlert: true, alerts: res });
        //   this.events.publish('alert:sound', { from: 'header', sound: true });
        // });
      }
    })
  }

  loadCommerce(val) {
    this.firestore.getWhere("commerces", "manager_user_uid", val).subscribe(res0 => {
      // console.log("res0: ",res0);
      if (res0 && res0.length > 0) {
        const comerce = res0.find(item => item.isYellowGoAffiliate === true);
        // console.log("comerce: ",comerce)
        if (comerce && comerce.isYellowGoAffiliate) {
          this.isYellowGoAffiliate = true;
          this.cash_flow_statement = (comerce.cash_flow_statement) ? comerce.cash_flow_statement : false;
          if (this.section_purchase_request) {
            // this.loadCategories(comerce.uid);
            this.loadItemsIngredients(comerce.uid);
          }
        }
      }
    });
  }

  loadItemsIngredients(val1) {
    this.firestore.getAll('commerces/' + val1 + '/ingredients').subscribe(async res1 => {
      this.tableDataProducts = [];
      // console.log("ingredients: ",res1)
      await res1.forEach(element => {
        if (element['stock'] && Number(element.stock / element.unit_package) <= 2) {
          this.tableDataProducts.push(element);
        }
      });

      if (this.tableDataProducts.length > 0) {
        this.notificacionSound.requestPermission().subscribe(permission => {
          let audio = new Audio();
          audio.src = "./../../assets/audio/windows_notificacion.mp3";
          audio.load();
          audio.play();
          setTimeout(() => {
            audio.load();
            audio.play();
          }, 1000)
        });
      }
    });
  }

  alert() {
    // console.log('alert publish')
    this.events.publish('main:alert', { 'openAlert': true });
    this.events.publish('alert:sound', { from: 'header', sound: false });
  }

  setCountAlert(val1) {
    if (this.countAlert != val1) {
      this.countAlert = val1;
    }
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    this.auth.logout().then((e) => {
      // console.log(e);
      // console.log('SignOut()');
      this.router.navigate(['/auth/login']);
    }).catch((e) => { console.log(e); });
  }

  emptyCacheAndHardReload() {
    // Borra la caché del navegador
    if (caches && caches.keys) {
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    }

    // Realiza una recarga forzada
    window.location.reload();
  }

  GotoOrders(id) {
    this.router.navigate(['order-show/' + id]);
  }

  Goto(id) {
    this.router.navigate([id]);
  }

  formatDate(time) {
    let options;
    options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    let date = new Date(time.seconds * 1000);
    let format = date.toLocaleDateString('es-ES', options);
    return format;
  }

  ToFixed(number) {
    return Number(number).toFixed(2);
  }

  openXlModal() {
    this.chat = [];
    this.message = '';

    if (this.view == 'CHAT CLIENTE') {
      this.sufix = this.sufix_client;
      this.loadChat();
    } else if (this.view == 'CHAT YET') {
      this.sufix = this.sufix_yet;
      this.loadChat();
    }

    // Use the NgbModal to open the modal with the specified content
    this.modalService.open(this.lgModal, { size: 'lg', centered: true }).result
      .then((result) => {
        // Handle modal closed event
        // console.log("Modal closed" + result);
      })
      .catch((res) => {
        // Handle modal dismissal/cancellation
      });
  }

  loadChat() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    // console.log("order: ", this.order);
    this.sub1 = this.firestore.getOne('orders_chat', `${this.orders[0].uid}${this.sufix}`).subscribe(res0 => {
      // console.log("orders_chat: ", res0);
      this.chat = [];
      if (res0 != undefined && res0.hasOwnProperty('messages') && res0.messages.length > 0) {
        res0.messages.forEach((item, idx, array) => {
          item.slot = item.rol == 'local' ? 'end' : 'start';
          if (this.view == 'DETALLE DEL PRODUCTO' || this.view == 'CHAT CLIENTE') {
            item.imgChat = item.rol == 'local' ? 'pin-local.png' : 'pin-client.png';
          } else if (this.view == 'CHAT YET') {
            item.imgChat = item.rol == 'local' ? 'pin-local.png' : 'pin-yet.png';
          }
          moment.locale('es');
          item.timeAgo = moment(item.time).fromNow();
          this.chat.push(item);
          if (idx === array.length - 1) { this.scrollToLabel('endChat'); }
        });
      }
    });
  }

  scrollToLabel(label) {
    // console.log("chat: ",this.chat);
    var titleELe = document.getElementById(label);
    titleELe.scrollIntoView({ behavior: 'smooth' });
  }

  sendMessage() {
    if (this.orders[0].orders_status_uid == "Entregada") {
      this.AlertsSwal('Orden entregada, ya no puedes responder a este chat', '', 'warning');
      this.message = '';
    } else if (this.chat != undefined && this.chat != null && this.chat.length == 0) {
      this.sendFirstMessage().then(() => {
        this.sendArrayMessage();
      }).catch((e) => {
        // console.log(e);
      })
    } else {
      this.sendArrayMessage();
    }
  }

  sendFirstMessage() {
    return new Promise((resolve, reject) => {
      let firstChat = {
        time: new Date().getTime(),
        timestamp: new Date(),
        uid: `${this.orders[0].uid}${this.sufix}`,
        messages: [],
      }
      this.firestore.save(`orders_chat`, firstChat).then((data) => {
        resolve(true);
      }).catch((error) => {
        // console.log(error);
        this.AlertsSwal('No se envio.. Intentalo mas tarde', '', 'warning');
        reject(false);
      });
    });
  }

  sendArrayMessage() {
    if (this.message != '') {
      let newMessage = {
        time: new Date().getTime(),
        timestamp: new Date(),
        message: this.message,
        rol: 'local',
        order_uid: this.orders[0].uid,
      };
      this.firestore.updateArrayMessages(`orders_chat/${this.orders[0].uid}${this.sufix}`, { ...newMessage }).then((res1) => {
        var chat = {
          active_notification: true,
        };

        // console.log("chat: ",chat);
        this.firestore.update('orders_chat', `${this.orders[0].uid}${this.sufix}`, { ...chat }).then((res2) => {
        }).catch((error) => {
          // console.log(error);
          this.AlertsSwal('No se envio.. Intentalo mas tarde', '', 'warning');
        });

        this.notification.getPushTokenFromUsr(this.view == 'CHAT YET' ? this.orders[0].yet_uid : this.orders[0].user_uid).then((res2) => {
          if (res2 != undefined && res2['pushToken'] != '') {
            // console.log(res2['pushToken']);
            this.notification.sendPush(res2['pushToken'], `orden #${this.orders[0].uid} Mensaje de local`, this.message)
              .then((res) => { console.log(res); this.message = ''; })
              .catch((e) => { console.log(e); this.message = ''; });
          } else {
            this.message = '';
          }
        }).catch((e) => {
          this.message = '';
          // console.log("Error al enviar notificacion => "+e); 
        });
      }).catch((error) => {
        // console.log(error);
        this.AlertsSwal('No se envio.. Intentalo mas tarde', '', 'warning');
      });
    }
  }

  AlertsSwal(header, message, icon) {
    Swal.close();
    Swal.fire(
      header,
      message,
      icon
    )
  }
}
