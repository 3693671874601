<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 xl-100" *ngIf="isYellowGoAffiliate">
            <div class="card height-equal">
                <div class="card-header">
                    <div class="row">
                        <div class="col-5">
                            <h5>Corte Diario</h5>
                        </div>
                        <div class="col-4">
                            <ng-container *ngIf="dataEmployee && dataEmployee.name">
                                <h5>Empleado: {{dataEmployee.name}} </h5>
                            </ng-container>
                        </div>
                        <div class="col-3">
                            <ng-container *ngIf="dataEmployee && dataEmployee.name">
                                <a href="javascript:void(0)" (click)="validateEmployeeSession()"
                                    style="position: absolute; top: 10px; padding: 2px 10px; font-size: 13px; height: 45px;"
                                    class="btn btn-primary mx-2">
                                    Cambiar cuenta de empleado
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="max-height: 550px; overflow: auto;">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Comercio</th>
                                    <th scope="col" width="10%">Saldo Actual</th>
                                    <th scope="col" width="10%">Ultimo Saldo reportado</th>
                                    <th scope="col">Ultimo Corte</th>
                                    <th scope="col">Ultima Apertura</th>
                                    <th scope="col" width="5%">Estado Caja</th>
                                    <th scope="col" width="5%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let group of data_commerces; index as i;">
                                    <td>{{i+1}}</td>
                                    <td>{{group.name}}</td>
                                    <td>$ {{(group.commerce_money || 0).toFixed(2)}}</td>
                                    <td>$ {{(group.commerce_last_money || 0).toFixed(2)}}</td>
                                    <ng-container *ngIf="group.last_daily_cut">
                                        <td>
                                            {{formatDate(group.last_daily_cut)}}
                                            <br>
                                            Hecho por:
                                            <br>
                                            <strong>
                                                {{group.last_daily_cut_by || 'N/A'}}
                                            </strong>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="!group.last_daily_cut">
                                        <td>N/A</td>
                                    </ng-container>
                                    <ng-container *ngIf="group.last_daily_opening">
                                        <td>
                                            {{formatDate(group.last_daily_opening)}}
                                            <br>
                                            Hecho por:
                                            <br>
                                            <strong>
                                                {{group.last_daily_opening_by || 'N/A'}}
                                            </strong>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="!group.last_daily_opening">
                                        <td>N/A</td>
                                    </ng-container>
                                    <td>
                                        <ng-container *ngIf="group.cash_flow_statement !== undefined">
                                            <span class="badge bg-primary" *ngIf="group.cash_flow_statement == true">
                                                Abierto
                                            </span>
                                            <span class="badge bg-danger" *ngIf="group.cash_flow_statement == false">
                                                Cerrado
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="group.cash_flow_statement === undefined">
                                            <span class="badge bg-danger">
                                                Cerrado
                                            </span>
                                        </ng-container>

                                    </td>
                                    <td>
                                        <div style="display: flex;">
                                            <a href="javascript:void(0)" (click)="openModal(group, lgModal)"
                                                style="padding: 2px 10px; font-size: 10px; height: 45px;"
                                                class="btn btn-primary mx-2" *ngIf="group.cash_flow_statement == true">
                                                Realizar Corte
                                            </a>
                                            <a href="javascript:void(0)" (click)="makeDiaryOpening(group)"
                                                *ngIf="group.cash_flow_statement === undefined || group.cash_flow_statement == false"
                                                style="padding: 2px 10px; font-size: 10px; height: 45px;"
                                                class="btn btn-dark">
                                                Realizar Apertura
                                            </a>
                                            <a href="javascript:void(0)" (click)="makeDiaryClosing(group)"
                                                *ngIf="group.cash_flow_statement == true"
                                                style="padding: 2px 10px; font-size: 10px; height: 45px;"
                                                class="btn btn-danger">
                                                Realizar Cierre
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'dollar-sign'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <span class="m-0">Ingreso mensual</span>
                            <h3 class="mb-0">$ <span class="counter" [CountTo]="profitsPerMonth" [from]="0"
                                    [duration]="1.5">{{ToFixed(profitsPerMonth)}}</span><small></small></h3>
                            <span class="m-0">{{profitsPerMonth_date}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6 xl-50">
            <div class="card o-hidden  widget-cards">
                <div class="bg-info card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'dollar-sign'" class="font-info"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <span class="m-0">Ingreso semanal</span>
                            <h3 class="mb-0">$ <span class="counter" [CountTo]="profitsPerWeek" [from]="0"
                                    [duration]="1.5">{{ToFixed(profitsPerWeek)}}</span><small></small></h3>
                            <span class="m-0">{{profitsPerWeek_date}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6 xl-50">
            <div class="card o-hidden  widget-cards">
                <div class="bg-dark card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'dollar-sign'" class="font-dark"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <span class="m-0">Ingreso diario</span>
                            <h3 class="mb-0">$ <span class="counter" [CountTo]="profitsPerDay" [from]="0"
                                    [duration]="1.5">{{ToFixed(profitsPerDay)}}</span><small></small></h3>
                            <span class="m-0">{{profitsPerDay_date}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-danger card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Pedidos pendientes</span>
                            <h3 class="mb-0"><span class="counter" [CountTo]="count_orders" [from]="0"
                                    [duration]="1.5">{{count_orders}}</span><small></small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-success card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'check-circle'" class="font-success"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Pedidos completados</span>
                            <h3 class="mb-0"><span class="counter" [CountTo]="count_orders_complete" [from]="0"
                                    [duration]="1.5">{{count_orders_complete}}</span><small></small></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6 xl-50">
            <div class="form-button">
                <button class="mx-3 btn btn-primary" type="submit" [routerLink]="['/' + marcadorPath, 'home']"
                    routerLinkActive="router-link-active" style="display: flex; align-items: center;">
                    <label for="">
                        Marcar Entrada
                    </label>
                    <app-feather-icons icon="arrow-right-circle" style="zoom: 1.5; margin-left: 5px;">
                    </app-feather-icons>
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header" style="padding-bottom: 0px;">
                    <div class="row">
                        <div class="col-12 col-md-4 col-xl-6" style="margin-bottom: 10px; display: flex;">
                            <h5>Pedidos</h5>
                        </div>
                        <div class="col-12 col-md-8 col-xl-6" style="display: flex; justify-content: flex-end;">
                            <div class="btn-group mb-3 mb-md-0" role="group" aria-label="Basic example">
                                <button type="button" style="padding-left: 10px; padding-right: 10px;"
                                    [class]="btn_date.today.class" (click)="changedTypeSearch(1)">Día</button>
                                <button type="button" style="padding-left: 10px; padding-right: 10px;"
                                    [class]="btn_date.week.class" (click)="changedTypeSearch(2)">Semana</button>
                                <button type="button" style="padding-left: 10px; padding-right: 10px;"
                                    [class]="btn_date.month.class" (click)="changedTypeSearch(3)">Mes</button>
                                <button type="button" style="padding-left: 10px; padding-right: 10px;"
                                    [class]="btn_date.year.class" (click)="changedTypeSearch(4)">Año</button>
                            </div>
                            <a href="javascript:void(0)" style="margin-left: 5px;"
                                (click)="show_filter_date_chart = !show_filter_date_chart"
                                class="btn btn-primary m-r-10">
                                <app-feather-icons [icon]="'filter'"></app-feather-icons>
                            </a>
                        </div>
                        <div *ngIf="show_filter_date_chart" class="col-12 col-md-12 col-xl-12"
                            style="animation:alternate; display: flex; justify-content: flex-end; padding-right: 53px;">
                            <div class="row">
                                <div class="col-12 col-md-5 col-xl-5">
                                    <h6 class="card-title" style="margin-bottom: 5px; margin-top: 5px;">Inicio</h6>
                                    <form class="d-flex">
                                        <div class="mb-2">
                                            <div class="input-group">
                                                <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                                    [(ngModel)]="selectedDate1" ngbDatepicker #d1="ngbDatepicker">
                                                <button class="input-group-text" type="button" (click)="d1.toggle()">
                                                    <i class="feather icon-calendar icon-md text-muted"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-12 col-md-5 col-xl-5">
                                    <h6 class="card-title" style="margin-bottom: 5px; margin-top: 5px;">Fin</h6>
                                    <form class="d-flex">
                                        <div class="mb-2">
                                            <div class="input-group">
                                                <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                                    [(ngModel)]="selectedDate2" ngbDatepicker #d2="ngbDatepicker">
                                                <button class="input-group-text" type="button" (click)="d2.toggle()">
                                                    <i class="feather icon-calendar icon-md text-muted"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-12 col-md-2 col-xl-2">
                                    <button type="button" (click)="SearchByDate()" class="btn btn-outline-primary"
                                        style="margin-top: 25px;">Buscar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="padding-top: 0px;">
                    <div class="row card-body widget-cards" style="margin-top: 20px;">
                        <div class="media-body col-12 col-md-4 col-xl-4">
                            <span class="m-0"><strong>De: {{date_from}}</strong></span>
                            <br>
                            <span class="m-0"><strong>Hasta: {{date_up_to}}</strong></span>
                        </div>
                        <div class="media-body col-6 col-md-4 col-xl-4">
                            <span class="m-0">Pedidos Completados</span>
                            <h3 *ngIf="refresh" class="mb-0">
                                <app-feather-icons [icon]="'shopping-bag'" class="font-warning"
                                    style="margin-right: 5px;"></app-feather-icons>
                                <span class="counter" [CountTo]="count_orders_date" [from]="0"
                                    [duration]="1">{{count_orders_date}}</span><small></small><span
                                    style="font-size:medium;"> pedidos</span>
                            </h3>
                        </div>
                        <div class="media-body col-6 col-md-4 col-xl-4">
                            <span class="m-0">Ventas Realizadas</span>
                            <h3 *ngIf="refresh" class="mb-0">
                                <app-feather-icons [icon]="'dollar-sign'" class="font-warning"></app-feather-icons>
                                <span class="counter" [CountTo]="count_orders_complete_date" [from]="0"
                                    [duration]="1">{{count_orders_complete_date}}</span><small></small>
                            </h3>
                        </div>
                        <div class="media-body col-12" *ngIf="!refresh">
                            <div class="loading-container">
                                <div class="loading-spinner"></div>
                            </div>
                        </div>
                        <div class="media-body col-12" *ngIf="show_message_no_data"
                            style="min-height: 300px; height: 100%; display: flex; align-items: center; justify-content: center;">
                            <div class="card-header">
                                <h5 style="color: #E0B006;">Sin ordenes</h5>
                            </div>
                        </div>
                    </div>
                    <div class="market-chart" *ngIf="refresh && !show_message_no_data">
                        <!-- <x-chartist class="board-chart ct-golden-section" [data]="chart3.data" [type]="chart3.type"
                            [options]="chart3.options" [events]="chart3.events">
                        </x-chartist> -->
                        <apx-chart class="board-chart ct-golden-section" [series]="revenueChartOptions.series"
                            [chart]="revenueChartOptions.chart" [colors]="revenueChartOptions.colors"
                            [grid]="revenueChartOptions.grid" [xaxis]="revenueChartOptions.xaxis"
                            [yaxis]="revenueChartOptions.yaxis" [markers]="revenueChartOptions.markers"
                            [stroke]="revenueChartOptions.stroke"></apx-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header" style="padding-bottom: 0px;">
                    <div class="row">
                        <div class="col-12 col-md-4 col-xl-6" style="margin-bottom: 10px;">
                            <h5>Más Vendidos</h5>
                        </div>
                        <div class="col-12 col-md-8 col-xl-6" style="display: flex; justify-content: flex-end;">
                            <div class="btn-group mb-3 mb-md-0" role="group" aria-label="Basic example">
                                <button type="button" style="padding-left: 10px; padding-right: 10px;"
                                    [class]="btn_top_sales.categories.class"
                                    (click)="changedTypeTopSales(1)">Categorias</button>
                                <button type="button" style="padding-left: 10px; padding-right: 10px;"
                                    [class]="btn_top_sales.products.class"
                                    (click)="changedTypeTopSales(2)">Productos</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="max-height: 550px; overflow: auto;">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th *ngIf="btn_top_sales.categories.active" scope="col">Categoria</th>
                                    <th *ngIf="btn_top_sales.products.active" scope="col">Producto</th>
                                    <th *ngIf="btn_top_sales.products.active" scope="col">Precio</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Ventas</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="btn_top_sales.categories.active">
                                <tr *ngFor="let group of data_sale_categories; index as i;">
                                    <td>{{i+1}}</td>
                                    <td>{{group.category}}</td>
                                    <td>{{group.quantity}}</td>
                                    <td>$ {{ToFixed(group.sales)}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="btn_top_sales.products.active">
                                <tr *ngFor="let group of data_sale_products; index as i;">
                                    <td>{{i+1}}</td>
                                    <td>{{group.category}}</td>
                                    <td>$ {{ToFixed(group.price)}}</td>
                                    <td>{{group.quantity}}</td>
                                    <td>$ {{ToFixed(group.sales)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Ultimas Ordenes</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Orden ID</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col"># Teléfono</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let items of latest_orders">
                                    <td># {{items.uid}}</td>
                                    <td class="digits">$ {{ToFixed(items.total)}}</td>
                                    <td class="font-danger">{{items.who_receives}}</td>
                                    <td class="font-danger">{{items.phone || 'N/A'}}</td>
                                    <td class="font-danger">{{items.orders_status_uid}}</td>
                                    <td class="digits">{{formatDate(items.created_at)}}</td>
                            </tbody>
                        </table>
                        <a [routerLink]="'/orders'" class="btn btn-primary">Ver todas las ordenes</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12" *ngIf="schedule.length > 0">
            <div class="card">
                <div class="card-header">
                    <h5>Horario dia {{day}}</h5>
                    <div style="display: flex;">
                        <p class="mb-3">Hora actual:</p>
                        <p class="mb-3" id="h4" style="margin-left: 5px;"></p>
                    </div>
                </div>
                <div class="card-body sell-graph">
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th colspan="2">Información</th>
                                    <th colspan="3" style="text-align: center;">Turno 1</th>
                                    <th colspan="2" style="text-align: center;">Turno 2</th>
                                </tr>
                                <tr>
                                    <th>
                                        Comercio
                                    </th>
                                    <th>
                                        Estado
                                    </th>
                                    <th>
                                        Hora de apertura
                                    </th>
                                    <th>
                                        Hora de cierre
                                    </th>
                                    <th>
                                        |
                                    </th>
                                    <th>
                                        Hora de apertura
                                    </th>
                                    <th>
                                        Hora de cierre
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of schedule; let i = index">
                                    <td>{{commerces_names[i]}}</td>
                                    <td>
                                        <span class="badge bg-primary"
                                            *ngIf="commerces_status[i] == true">Abierto</span>
                                        <span class="badge bg-danger"
                                            *ngIf="commerces_status[i] == false">Cerrado</span>
                                    </td>
                                    <td style="text-align: center;">
                                        {{item[0].schStart1}}
                                    </td>
                                    <td style="text-align: center;">
                                        {{item[0].schEnd1}}
                                    </td>
                                    <td>
                                        |
                                    </td>
                                    <td style="text-align: center;">
                                        {{item[0].schStart2}}
                                    </td>
                                    <td style="text-align: center;">
                                        {{item[0].schEnd2}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->


<!-- Modal -->
<ng-template #lgModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{view}}</h5>
        <button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>
    </div>
    <div class="modal-body" id="modal_body">
        <div class="row">
            <div class="col-12 col-md-12 col-xl-12">
                <div class="card overflow-hidden">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-8 col-md-8 col-xl-8 mb-3">
                                <h6 class="card-title">Efectivo</h6>
                                <input type="number" class="form-control" id="exampleInputText1" placeholder="0.00"
                                    [(ngModel)]="model.cash_payment">
                            </div>
                            <div class="col-4 col-md-4 col-xl-4 mb-3">
                                <div class="d-flex align-items-baseline mb-2">
                                    <h6 class="card-title">Ventas por Efectivo</h6>
                                    <span
                                        ngbPopover='Total calculado por el sistema.'
                                        popoverTitle="Total de Pago por Efectivo" triggers="mouseenter:mouseleave" placement="start">
                                        <app-feather-icons icon="info" style="zoom: 0.8; margin-right: 10px;">
                                        </app-feather-icons>
                                    </span>
                                </div>
                                <h6 class="card-title">${{(amount_sales.cash || 0).toFixed(2)}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8 col-md-8 col-xl-8 mb-3">
                                <h6 class="card-title">N1co</h6>
                                <input type="number" class="form-control" id="exampleInputText1" placeholder="0.00"
                                    [(ngModel)]="model.n1co_payment">
                            </div>
                            <div class="col-4 col-md-4 col-xl-4 mb-3">
                                <div class="d-flex align-items-baseline mb-2">
                                    <h6 class="card-title">Ventas por Tarjeta N1co</h6>
                                    <span
                                        ngbPopover='Total calculado por el sistema.'
                                        popoverTitle="Total de Pago por Tarjeta" triggers="mouseenter:mouseleave" placement="start">
                                        <app-feather-icons icon="info" style="zoom: 0.8; margin-right: 10px;">
                                        </app-feather-icons>
                                    </span>
                                </div>
                                <h6 class="card-title">${{((amount_sales.n1co) || 0).toFixed(2)}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8 col-md-8 col-xl-8 mb-3">
                                <h6 class="card-title">Compras realizadas desde el app</h6>
                                <input type="number" class="form-control" id="exampleInputText1" placeholder="0.00"
                                    [(ngModel)]="model.ianos_payment">
                            </div>
                            <div class="col-4 col-md-4 col-xl-4 mb-3">
                                <div class="d-flex align-items-baseline mb-2">
                                    <h6 class="card-title">Ventas desde Yelou App</h6>
                                    <span
                                        ngbPopover='Total calculado por el sistema.'
                                        popoverTitle="Total de Pago por Tarjeta - Yelou App" triggers="mouseenter:mouseleave" placement="start">
                                        <app-feather-icons icon="info" style="zoom: 0.8; margin-right: 10px;">
                                        </app-feather-icons>
                                    </span>
                                </div>
                                <h6 class="card-title">${{((amount_sales.app) || 0).toFixed(2)}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8 col-md-8 col-xl-8 mb-3">
                                <h6 class="card-title">Compras realizadas desde PedidosYa</h6>
                                <input type="number" class="form-control" id="exampleInputText1" placeholder="0.00"
                                    [(ngModel)]="model.pedidosYa_payment">
                            </div>
                            <div class="col-4 col-md-4 col-xl-4 mb-3">
                                <div class="d-flex align-items-baseline mb-2">
                                    <h6 class="card-title">Ventas por PedidosYa</h6>
                                    <span
                                        ngbPopover='Total calculado por el sistema.'
                                        popoverTitle="Total de por ventas realizadas en PedidosYa" triggers="mouseenter:mouseleave" placement="start">
                                        <app-feather-icons icon="info" style="zoom: 0.8; margin-right: 10px;">
                                        </app-feather-icons>
                                    </span>
                                </div>
                                <h6 class="card-title">${{((amount_sales.pedidosYa) || 0).toFixed(2)}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 col-md-5 col-xl-5 mb-3">
                                <div class="d-flex align-items-baseline mb-2">
                                    <h6 class="card-title">Ventas por Transferencia Bancaria</h6>
                                    <span
                                        ngbPopover='Total calculado por el sistema.'
                                        popoverTitle="Total de Pago por Transferencia Bancaria" triggers="mouseenter:mouseleave" placement="end">
                                        <app-feather-icons icon="info" style="zoom: 0.8; margin-right: 10px;">
                                        </app-feather-icons>
                                    </span>
                                </div>
                                <h6 class="card-title">${{((amount_sales.bankTransfer) || 0).toFixed(2)}}</h6>
                            </div>
                            <div class="col-4 col-md-4 col-xl-4 mb-3">
                                <div class="d-flex align-items-baseline mb-2">
                                    <h6 class="card-title">Ventas por CryptoMoneda</h6>
                                    <span
                                        ngbPopover='Total calculado por el sistema.'
                                        popoverTitle="Total de Pago por CryptoMoneda" triggers="mouseenter:mouseleave" placement="start">
                                        <app-feather-icons icon="info" style="zoom: 0.8; margin-right: 10px;">
                                        </app-feather-icons>
                                    </span>
                                </div>
                                <h6 class="card-title">${{((amount_sales.crypto) || 0).toFixed(2)}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 col-md-4 col-xl-4 mb-3">
                                <div class="d-flex align-items-baseline mb-2">
                                    <h6 class="card-title">Saldo al Aperturar</h6>
                                    <span
                                        ngbPopover='Monto ingresado al Aperturar Caja.'
                                        popoverTitle="Monto Inicial" triggers="mouseenter:mouseleave" placement="end">
                                        <app-feather-icons icon="info" style="zoom: 0.8; margin-right: 10px;">
                                        </app-feather-icons>
                                    </span>
                                </div>
                                <h6 class="card-title">${{(dataCommerce.cash_daily_opening || 0).toFixed(2)}}</h6>
                            </div>
                            <div class="col-4 col-md-4 col-xl-4 mb-3">
                                <div class="d-flex align-items-baseline mb-2">
                                    <h6 class="card-title">Saldo Actual</h6>
                                    <span
                                        ngbPopover='Total calculado por el sistema.'
                                        popoverTitle="Ventas Totales del Dia" triggers="mouseenter:mouseleave" placement="end">
                                        <app-feather-icons icon="info" style="zoom: 0.8; margin-right: 10px;">
                                        </app-feather-icons>
                                    </span>
                                </div>
                                <h6 class="card-title">${{(dataCommerce.commerce_money || 0).toFixed(2)}}</h6>
                            </div>
                            <div class="col-4 col-md-4 col-xl-4 mb-3">
                                <div class="d-flex align-items-baseline mb-2">
                                    <h6 class="card-title">Saldo Actual + Saldo Inicial</h6>
                                    <span
                                        ngbPopover='Suma de las ventas totales del dia y el monto con el que se inicio el dia.'
                                        popoverTitle="Total Esperado" triggers="mouseenter:mouseleave" placement="start">
                                        <app-feather-icons icon="info" style="zoom: 0.8; margin-right: 10px;">
                                        </app-feather-icons>
                                    </span>
                                </div>
                                <h6 class="card-title">${{((dataCommerce.commerce_money + dataCommerce.cash_daily_opening) || 0).toFixed(2)}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 col-md-4 col-xl 4 mb3"></div>
                            <div class="col-4 col-md-4 col-xl-4 mb-3">
                                <div class="d-flex align-items-baseline mb-2">
                                    <h6 class="card-title">Saldo a Reportar</h6>
                                    <span
                                        ngbPopover='Suma de totales ingresados por el usuario.'
                                        popoverTitle="Ventas Totales del Dia" triggers="mouseenter:mouseleave" placement="start">
                                        <app-feather-icons icon="info" style="zoom: 0.8; margin-right: 10px;">
                                        </app-feather-icons>
                                    </span>
                                </div>
                                <h6 class="card-title">${{((model.cash_payment + model.n1co_payment +
                                    model.ianos_payment + model.pedidosYa_payment) || 0).toFixed(2)}}</h6>
                            </div>
                            <div class="col-4 col-md-4 col-xl 4 mb3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dangerç" (click)="modal.close('by: close button')">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="makeACut()">Realizar Corte</button>
    </div>
</ng-template>

<!-- Modal -->
<ng-template #lgModal_2 let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Apertura de Caja</h5>
        <button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>
    </div>
    <div class="modal-body" id="modal_body">
        <div class="row">
            <div class="col-12 col-md-12 col-xl-12">
                <div class="card overflow-hidden">
                    <div class="card-body">
                        <div class="row">
                            <!-- <div class="col-md-4">
                            </div> -->
                            <div class="col-md-4">
                                <h6 class="card-title">Monto de Apertura de Caja</h6>
                                <input type="number" class="form-control mb-3" id="exampleInputText1" placeholder="0.00"
                                    [(ngModel)]="moneyDiaryOpening">
                                <h6 class="card-title">Comentarios de apertura</h6>
                                <textarea class="form-control" id="exampleFormControlTextarea1"
                                    [(ngModel)]="postCutDiaryOpening.comments" rows="5"></textarea>
                            </div>
                            <div class="col-md-8">
                                <h6 class="card-title">Imagenes de apertura</h6>
                                <!-- <div class="dropzone" [dropzone]="config" (removedFile)="onremovedFile($event)"
                                    (error)="onUploadError($event)" (success)="onUploadSuccess($event)"></div> -->

                                <div class="dropzone-custom">
                                    <ngx-dropzone class="dropzone-border" (change)="onUploadSuccess($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                            </div>
                                        </ngx-dropzone-label>
                                        <!-- <ngx-dropzone-preview *ngFor="let f of postCutDiaryOpening.images"
                                            [removable]="true" (removed)="onremovedFile(f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview> -->
                                        <ngx-dropzone-image-preview class="dropzone-border"
                                            ngProjectAs="ngx-dropzone-preview" [removable]="true"
                                            (removed)="onremovedFile(f)" *ngFor="let f of imagesCutDiaryOpening"
                                            [file]="f">
                                            <!-- <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label> -->
                                        </ngx-dropzone-image-preview>
                                    </ngx-dropzone>

                                </div>
                                <!-- <button [hidden]="true" class="btn btn-dark mt-2 d-block ms-auto"
                                    (click)="resetDropzoneUploads()">Reset</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dangerç" (click)="modal.close('by: close button')">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="saveDiaryOpening()">Guardar Apertura</button>
    </div>
</ng-template>